import "./App.css";
import profile from "./images/profile.jpg";
function App() {
  return (
    <div className="App">
      <>
        <img src={profile} alt="profile" />
        <h1>PIOTR STĘPIEŃ</h1>
      </>
      <h3>piterst25@gmail.com</h3>
    </div>
  );
}

export default App;
